export const shouldUpdateScroll = args => {
  if (!["/people", "/contact", "/"].includes(args.pathname)) {
    return false
  }
}

export const onPrefetchPathname = ({ loadPage }) => {
  if (window.indexPageData === undefined) {
    loadPage("/").then(result => {
      window.indexPageData = result

      if (window.setIndexPageData) window.setIndexPageData()
    })
  }
  if (window.indexPageData === undefined) {
    loadPage("/people").then(result => {
      window.peoplePageData = result

      if (window.setPeoplePageData) window.setPeoplePageData()
    })
  }
}
